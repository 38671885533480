import * as $ from "jquery"
import moment from "moment"

document.addEventListener("DOMContentLoaded", (event) => {
    const $agentsSelect2 = $("#js-select2-agents");
    const $timeZonesSelect2 = $("#js-select2-timezones");
    const $timeLogsDaterange = $("#js-daterange-timelogs");
    let queryParams = new URLSearchParams(window.location.search);
    const date = new Date();
    const type = queryParams.get("type") || "daily";
    const dateFrom = queryParams.get("date_from") || moment(date).format("YYYY-MM-DD");
    const dateTo = type === "daily" ? queryParams.get("date_to") || dateFrom : queryParams.get("date_to");


    $agentsSelect2.select2({
        placeholder: "Select an agent",
        allowClear: true,
    });

    $agentsSelect2.on("change", function(e) {
        filter(`/reports/user_time_logs/${e.target.value}`);
    });

    $timeZonesSelect2.select2({
        placeholder: "Select time zone",
        allowClear: true,
    });

    $timeZonesSelect2.on("change", function(e) {
        const value = e.target.value;
        if (value) queryParams.set("timezone", e.target.value);
        if (!value) queryParams.delete("timezone");
        filter();
    });

    $timeLogsDaterange.daterangepicker({
        opens: "left",
        locale: {
            format: "MMMM D, YYYY"
        },
        startDate: dateFrom ? moment(dateFrom) : moment(date),
        endDate: dateTo ? moment(dateTo) : moment(date),
        showDropdowns: true,
        alwaysShowCalendars: true,
        ranges: {
            "Today": [moment(), moment()],
            "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
        }
    }, onApplyDateRange);

    function onApplyDateRange(start, end) {
        let dateFrom = start;
        let dateTo = end;
        if (type !== "daily") {
            dateFrom = start.startOf("week");
            dateTo = end.endOf("week");
        }
        queryParams.set("date_from", dateFrom.format("YYYY-MM-DD"));
        queryParams.set("date_to", dateTo.format("YYYY-MM-DD"));
        filter();
    }

    $(".js-time-log-type").on("click", onClickTimeLogType);
    function onClickTimeLogType() {
        queryParams.set("type", $(this).val());
        queryParams.delete("page");
        filter();
    }

    $(".js-weekly-table-data").on("click", onClickWeeklyTableData);
    function onClickWeeklyTableData() {
        queryParams.set("date_from", $(this).attr("data-value"));
        queryParams.set("type", "daily");
        queryParams.delete("date_to");
        filter();
    }

    $(".js-date-navigation").on("click", onClickDateNavigation);
    function onClickDateNavigation() {
        let navigateType = "day";
        if (type !== "daily") navigateType = "week";

        let newDateFrom = moment(dateFrom).subtract(1, navigateType);
        if ($(this).val() === "next") newDateFrom = moment(dateFrom).add(1, navigateType);

        queryParams.set("date_from", newDateFrom.format("YYYY-MM-DD"));
        queryParams.delete("date_to");
        filter();
    }

    $("#js-timelogs-clear-filter").on("click", onClickClearFilters);
    function onClickClearFilters() {
        window.location.replace(window.location.pathname);
    }

    function filter(pathname = window.location.pathname) {
        window.location.replace(`${pathname}?${queryParams.toString()}`);
    }
})